import { useState } from "react";
import { checkWinner, Player } from "./utils";
import { SmallBoard } from "./SmallBoard";
import classNames from "classnames";

export const GameBoard = () => {
  const [isXNext, setIsXNext] = useState(false);
  const [disabledBoards, setDisabledBoards] = useState(Array(9).fill(false));
  const [state, setState] = useState(Array(9).fill(null));
  const [boardStates, setBoardStates] = useState(
    Array(9).fill(Array(9).fill(null))
  );
  const [winner, setWinner] = useState<Player>(null);
  const player = isXNext ? "X" : "O";

  const handleMove = (boardIndex: number, moveIndex: number) => {
    if (winner || disabledBoards[boardIndex]) {
      return;
    }
    const newBoardState = [
      ...boardStates[boardIndex].slice(0, moveIndex),
      player,
      ...boardStates[boardIndex].slice(moveIndex + 1),
    ];
    const newBoardStates = [
      ...boardStates.slice(0, boardIndex),
      newBoardState,
      ...boardStates.slice(boardIndex + 1),
    ];
    setBoardStates(newBoardStates);

    const newState = [...state];
    if (checkWinner(newBoardState, moveIndex)) {
      newState[boardIndex] = player;
      setState(newState);
      if (checkWinner(newState, boardIndex)) {
        setDisabledBoards(Array(9).fill(true));
        setWinner(player);
        return;
      }
    }

    if (
      newBoardStates[moveIndex].indexOf(null) > -1 &&
      newState[moveIndex] === null
    ) {
      const boards = Array(9).fill(true);
      boards[moveIndex] = false;
      setDisabledBoards(boards);
    } else {
      setDisabledBoards(Array(9).fill(false));
    }

    setIsXNext(!isXNext);
  };

  const restart = () => {
    setDisabledBoards(Array(9).fill(false));
    setWinner(null);
    setBoardStates(Array(9).fill(Array(9).fill(null)));
    setState(Array(9).fill(null));
  };
  return (
    <>
      <header className="text-xl m-8">Ultimate Tic Tac Toe</header>
      <div className="inline-grid grid-cols-3 min-w-max">
        {Array.from(Array(9).keys()).map((boardIndex) => (
          <SmallBoard
            key={boardIndex}
            index={boardIndex}
            won={state[boardIndex]}
            state={boardStates[boardIndex]}
            handleMove={(boardIndex, moveIndex) =>
              handleMove(boardIndex, moveIndex)
            }
            disabled={disabledBoards[boardIndex]}
          />
        ))}
      </div>
      <div className="mt-4">
        {winner ? `Congrats ${winner}` : `Next player: ${player}`}
      </div>
      <button className="mt-4 p-1 border border-black" onClick={() => restart()}>Restart</button>
    </>
  );
};
