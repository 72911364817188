export type Player = "X" | "O" | null;

export const getRowColumn = (index: number) => ({
  column: index % 3,
  row: Math.floor(index / 3),
});

export const checkWinner = (boardState: number[], moveIndex: number) => {
  const { column, row } = getRowColumn(moveIndex);
  const firstIndexInRow = row * 3;
  return (
    (boardState[column] === boardState[column + 3] &&
      boardState[column] === boardState[column + 6]) ||
    (boardState[firstIndexInRow] === boardState[firstIndexInRow + 1] &&
      boardState[firstIndexInRow] === boardState[firstIndexInRow + 2]) ||
    (row === column &&
      boardState[0] === boardState[4] &&
      boardState[0] === boardState[8]) ||
    (row + column === 2 &&
      boardState[2] === boardState[4] &&
      boardState[2] === boardState[6])
  );
};
