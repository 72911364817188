import classNames from "classnames";

import { getRowColumn, Player } from "./utils";
import { Field } from "./Field";

interface SmallBoardProps {
  index: number;
  won: Player;
  state: Player[];
  handleMove: (boardIndex: number, moveIndex: number) => void;
  disabled: boolean;
}

export const SmallBoard: React.FC<SmallBoardProps> = ({
  index,
  won,
  state,
  handleMove,
  disabled,
}) => {
  const { column, row } = getRowColumn(index);

  return (
    <div
      className={classNames("border-black", {
        "border-b-2": row < 2,
        "border-r-2": column < 2,
      })}
    >
      {won && (
        <div className="absolute w-24 h-24 text-8xl mt-2 ml-2 md:ml-8 md:mt-8">
          {won}
        </div>
      )}
      <div
        className={classNames(
          "p-2 md:p-8 inline-grid grid-cols-3 grid-rows-3",
          { "opacity-20": disabled || won }
        )}
      >
        {state.map((value, ind) => (
          <Field
            key={`${index}-${ind}`}
            index={ind}
            value={value}
            handleClick={(i) => handleMove(index, i)}
          />
        ))}
      </div>
    </div>
  );
};
