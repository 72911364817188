import { GameBoard } from "./GameBoard";

function App() {
  return (
    <div className="text-center">
      <GameBoard />
    </div>
  );
}

export default App;
