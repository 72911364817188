import classNames from "classnames";

import { getRowColumn, Player } from "./utils";

interface FieldProps {
  index: number;
  value: Player;
  handleClick: (index: number) => void;
}

export const Field: React.FC<FieldProps> = ({ index, value, handleClick }) => {
  const { column, row } = getRowColumn(index);
  return (
    <div
      className={classNames(
        "w-8",
        "h-8",
        "m-auto",
        "leading-8",
        "border-black",
        "cursor-pointer",
        {
          "border-b": row < 2,
          "border-r": column < 2,
        }
      )}
      onClick={() => handleClick(index)}
    >
      {value}
    </div>
  );
};
